.base-sidebar {
  width: 280px;
  overflow: hidden;
  position: relative;

  &__paper {
    min-width: 310px;
    max-width: 350px;
    width: 100%;
  }
}
