.permission-info {
  position: absolute;
  z-index: 1000;
  &__sign {
    position: relative;
    z-index: 101;
    font-size: 24px;
    text-align: center;
    border-radius: 50%;
    padding: 10px;
    height: 50px;
    width: 50px;
    color: #fff;
    background-color: #5e8fe3;
    font-weight: 700;
    border: none;
    transition: all 0.4s;
    &:hover {
      box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3);
    }
    &:active {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    }
  }

  &__sign:active + &__detail {
    display: block;
    animation-name: example;
    animation-duration: 1s;
  }

  &__detail {
    position: absolute;
    z-index: 100;
    display: none;
    max-width: 200px;
    min-width: 150px;
    left: -265%;
    top: 15px;
    transition: all 0.3s;
  }

  &__list {
    list-style: none;
    border: 1px solid #5e8fe3;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.3);
  }

  &--right-position {
    top: 8px;
    right: 8px;
  }
}

@keyframes example {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
